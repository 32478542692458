/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, audioSpriteVolume, config } from '../../config';
import { BetBonusReward, EventTypes, GameMode, GraphQLErrorsType, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  replayBetGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
  stressfulGql,
} from '../../gql';
import {
  setIsBuyFeaturePurchased,
  setIsPickemShotInProgress,
  setIsProcessToGame,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setReplayBet,
  setReplayFreeSpinBets,
} from '../../gql/cache';
import { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import {
  canPressSpin,
  getSoundToPlay,
  isFreeSpinMode,
  isPickemMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';

import { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSets } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const lineSet = lineSets[0];
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<unknown>): void => {
    eventManager.emit('placeBetCompleted');
    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.placed,
        },
      },
    });

    if (setIsPickemShotInProgress()) {
      setIsPickemShotInProgress(false);
      eventManager.emit(EventTypes.PICKEM_SHOT_RESOLVED, placeBet);

      return;
    }

    SlotMachine.getInstance().setResult(placeBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    if (isFreeSpinMode(setGameMode())) {
      eventManager.emit(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
      setGameHistory(lastThreeSpins);
      if (placeBet.bet.coinAmount * setSlotConfig().lineSets[0].coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
        AudioHowl.fadeIn(3000, ISongs.BaseGameBGM_Melo);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };
  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      eventManager.emit('placeBetCompleted');
      if (error.graphQLErrors.some((err) => err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },

    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError() {
      eventManager.emit('placeBetCompleted');
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      setWinAmount(0);
      setLastRegularWinAmount(0);
      const spinState = SlotMachine.getInstance().state;
      if (spinState !== SlotMachineState.IDLE || (!isFreeSpinMode(setGameMode()) && !setBrokenGame())) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinMode(setGameMode()) || setBrokenGame() || isPickemMode(setGameMode())) return;
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          fnGet({
            variables: {
              input: {
                slotId,
                coinAmount: setBetAmount() / 20,
                coinValue: setCoinValue(),
                lineSetId: setSlotConfig().lineSets[0].id,
              },
            },
          });
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioHowl.isRestricted) {
          AudioHowl.changeRestriction(false, [
            { type: ISongs.BaseGameBGM_Base },
            { type: ISongs.BaseGameBGM_Melo, volume: 0 },
          ]);
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        const soundToPlay = getSoundToPlay();

        AudioHowl.changeRestriction(
          false,
          soundToPlay,
          () => setIsSoundLoading(true),
          () => setIsShowSoundToast(false),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        const replayFreeSpins = setReplayFreeSpinBets();
        if (replayFreeSpins.length) {
          setReplayBet(replayFreeSpins[setCurrentBonus().currentRound - 1]);
        }
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setBetAmount() / 20,
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSets[0].id,
              userBonusId: setCurrentBonusId(),
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
    };
    const buyFeatureSpin = () => {
      if (setReplayBet()) return;
      setIsBuyFeaturePurchased(false);
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSets[0].id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
      );
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(false, [
          { type: ISongs.BaseGameBGM_Base },
          { type: ISongs.BaseGameBGM_Melo, volume: 0 },
        ]);
      }
    };
    const pickemSpin = () => {
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSets[0].id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
    };
    eventManager.on(EventTypes.PICKEM_SHOT, pickemSpin);
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
      eventManager.removeListener(EventTypes.PICKEM_SHOT, pickemSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(
    (isSpaceClick?: boolean) => {
      if (setIsAutoSpins() && !stressful?.stressful.show) {
        const autoSpinsLeft = setAutoSpinsLeft() <= 0;
        const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
        const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

        const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

        const balanceIncrease =
          setIsStopOnBalanceIncrease() &&
          balanceAmount &&
          setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

        const balanceDecrease =
          setIsStopOnBalanceDecrease() &&
          balanceAmount &&
          setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

        if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
          setIsAutoSpins(false);
          if (!isSpaceClick) {
            eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          }
        } else {
          onSpin(isTurboSpin);
        }
      }
    },
    [balanceAmount, onSpin, isTurboSpin, stressful?.stressful],
  );

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if ((setGameMode() === GameMode.REGULAR || setGameMode() === GameMode.BUY_FEATURE) && setIsFreeSpinsWin()) {
      return;
    }
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (!setIsProcessToGame()) return;

        if (data?.isEnabledSpaceSpin) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            // we cannot fix it in canPressSpin method coz its in locked file. hardcode solution
            bonusCurrentRound: 2,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isPickem: gameMode === GameMode.PICKEM,
          })
        ) {
          return;
        }

        if (data?.isEnabledSpaceSpin && isAutoSpins) {
          checkAutoSpinSettings(true);
          return;
        }
        if (data?.isEnabledSpaceSpin && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = !_.reduce(winThreeTimes, (acc, item) => acc || item);

    if (play) {
      AudioHowl.fadeIn(500, ISongs.BaseGameBGM_Melo);
      // AudioHowl.fadeOut(
      //   500,
      //   ISongs.BaseGameBGM_Base,
      //   audioSpriteVolume[ISongs.BaseGameBGM_Base] / 3,
      // );
    }

    if (stop) {
      AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);
      // AudioHowl.fadeIn(
      //   3000,
      //   ISongs.BaseGameBGM_Base,
      //   audioSpriteVolume[ISongs.BaseGameBGM_Base],
      // );
    } else {
      timeout = setTimeout(() => {
        AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);

        // AudioHowl.fadeIn(
        //   3000,
        //   ISongs.BaseGameBGM_Base,
        //   audioSpriteVolume[ISongs.BaseGameBGM_Base],
        // );
      }, 30000);
    }
  }, [winThreeTimes]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
